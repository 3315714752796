<template>
  <div class="main home-p">
    <v-container class="pt-0">
      <div class="banner">
        <v-parallax color="grey darken-3" src="/images/bansky.jpg" class="pa-0 mb-12">

          <v-row
              style="height: 400px; background: rgba(0,0,0,0.2)"
              align="center"
              justify="start"
              class="pa-12"
          >
            <v-col
                class=""
                cols="12"
                md="6"
            >
              <h1 class="text-h4 mb-7">
                Welcome to <span class="font-weight-bold">
              Yorkshire <span class="primary--text">Bath</span> Resurfacing
            </span>, one of the oldest running resurfacing companies.
              </h1>
              <h4 class="subheading">
                Yorkshire Bath Resurfacing is one of the oldest resurfacing companies still operating today. We have been
                providing an excellent service since 1991 and it continues to this day.            </h4>
            </v-col>
          </v-row>

        </v-parallax>



        <v-row class="mb-12" >
          <v-col cols="12" md="4" class="text-center">
            <v-icon color="primary" class="text-h2 mb-4">
              mdi-lock
            </v-icon>
            <h3 class="title text-center mb-4 text-h4">
              Trusted
            </h3>
            <p class="text-center body-1">Yorkshire Bath Resurfacing is trusted by some of the UK's most recognised names and councils to
              resurface their baths, some of which include Hilton hotel group, Castle Howard and more. </p>
          </v-col>
          <v-col cols="12" md="4" class="text-center">
            <v-icon color="primary" class="text-h2 mb-4">
              mdi-history
            </v-icon>
            <h3 class="text-h4 text-center mb-4">History</h3>
            <p class="text-center body-1"> Yorkshire Bath Resurfacing is one of the oldest resurfacing companies still operating today. We have
              been providing an excellent service since 1991 and it continues to this day. </p>
          </v-col>
          <v-col cols="12" md="4" class="text-center">
            <v-icon color="primary" class="text-h2 mb-4">
              mdi-map
            </v-icon>
            <h3 class="text-h4 text-center mb-4">Locations</h3>
            <p class="text-center body-1"> We provide our service all across the UK and sometimes abroad. If you'd like more information on our
              services and the work we do, we are always happy to have a chat. Please find our contact details by
              following the link bellow. </p>
          </v-col>
        </v-row>

        <v-row class="mt-6 mb-6 mt-12 mb-12">
          <v-col cols="12" class="text-center">
            <h3 class="display-2 mb-4">Yorkshire <span class="primary--text">Bath</span> Resurfacing</h3>
            <p class="body-1 mb-4">We provide an unbeatable service at an afordable price.</p>
            <v-btn color="primary"
                   @click="$router.push({name:'Contact'})"
                   :outlined="true">
              Request a Quote
            </v-btn>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <h3 class="font-weight-bold title mb-4">What makes us differed?</h3>
            <v-img src="/images/whyus.jpg" min-height="250"></v-img>
          </v-col>
          <v-col cols="12" md="6">
            <div class="ybr-tabs">
              <div v-for="tab in tabs" class="tab">
               <h4 @click="setActiveTab(tab)" class="title font-weight-bold mb-4">
                 <v-icon v-show="!tab.active">
                   mdi-plus
                 </v-icon>
                 <v-icon v-show="tab.active">
                   mdi-minus
                 </v-icon>
                 {{ tab.title }}
              </h4>

                  <div

                       v-if="tab.active" class="tab-content pl-7 mt-2 body-1 mb-12">
                    <v-fade-transition>
                      <div v-html="tab.text"></div>
                    </v-fade-transition>

                  </div>


              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
  export default {
    data: () => ({
      tabs: [
        {
          title: 'History',
          text: '<p>Yorkshire Bath Resurfacing is one of the oldest resurfacing companies still operating today. We have been providing an excellent service since 1991 and it continues to this day.</p>',
          active: true
        },
        {
          title: 'Unique Blend',
          text: '<p> We use an extremely tough, unique, durable and hard wearing material for cast iron and pressed steel baths, basins, pedestals, shower trays, Belfast sinks, Aga ranges and more.</p>' +
                '<p>We make our own chemical products to ensure our service is the best and most affective. </p>',
          active: false
        },
        {
          title: 'Our Guarantee',
          text: '<p>We are so confident in our process, that we offer anyone who gets a bath resurfaced 12 months guarantee subject to terms and conditions</p>',
          active: false
        },
      ]
    }),
    created() {

    },
    watch: {

    },
    methods: {
      setActiveTab(tab) {
        this.tabs.find(o => o.active === true).active = false;
        tab.active = true;
      }
    }
  }
</script>

<style lang="scss">
  .home-p {
    .ybr-tabs {
      h4 {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .banner {
      background: url('/images/banner2.png') center center;
      background-size: cover;

    }
  }
</style>